import React from 'react'

import classnames from 'classnames'
import Avatar from 'react-avatar'
import ReactCountryFlag from 'react-country-flag'
import { v4 as uuid } from 'uuid'

import { LinkCustom } from '../LinkCustom'
import { PeopleSocial } from '../PeopleSocial'

interface IPeopleListProps {
  people: {
    nodes: []
  }
  social?: boolean
  shape?: string
  align?: string
  className?: string
}

export const PeopleList: React.FC<IPeopleListProps> = ({
  people,
  social = true,
  align = 'text-left',
  className = 'sm:grid sm:grid-cols-3 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8',
}) => {
  return (
    <ul className={classnames('space-y-12', className)}>
      {people.nodes.map(({ data }) => (
        <li key={uuid()}>
          <div className="space-y-4">
            {data.Avatar___profile_image ? (
              <div>
                <Avatar
                  src={data.Avatar___profile_image[0].thumbnails.large.url}
                  className="rounded-xl object-cover shadow-lg"
                  alt={data.Full_name}
                  unstyled
                />
              </div>
            ) : (
              <Avatar
                name={data.Full_name}
                className="rounded-xl object-cover shadow-lg"
                size="248"
              />
            )}

            <div className={classnames('space-y-2 pt-3', align)}>
              <div className="space-y-1 flex justify-between content-centern items-center">
                <h3 className="text-xl leading-6 font-medium">
                  {data.Personal_website___blog ? (
                    <LinkCustom
                      href={data.Personal_website___blog}
                      className="hover:underline"
                      title={`Website / Blog of ${data.Full_name}`}
                    >
                      {data.Full_name}
                    </LinkCustom>
                  ) : (
                    <>{data.Full_name}</>
                  )}
                </h3>

                {data.Code_country && (
                  <ReactCountryFlag
                    countryCode={data.Code_country[0].toString().toLowerCase()}
                    svg
                    style={{ width: '1.3em', height: '1.3em', marginTop: 0 }}
                    title={data.Country[0].data.Name_country}
                  />
                )}
              </div>
              {data.Title && <p className="text-primary">{data.Title}</p>}
              {data.Url_episode__from_Episode_ && (
                <LinkCustom
                  to={data.Url_episode__from_Episode_}
                  className="flex items-center space-x-1 pb-2 hover:underline"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 5l7 7-7 7M5 5l7 7-7 7"
                    />
                  </svg>
                  <span>View episode</span>
                </LinkCustom>
              )}
              {social === true && <PeopleSocial data={data} />}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
